// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-blog-detail-js": () => import("./../../../src/components/blog/BlogDetail.js" /* webpackChunkName: "component---src-components-blog-blog-detail-js" */),
  "component---src-components-blog-blog-list-js": () => import("./../../../src/components/blog/BlogList.js" /* webpackChunkName: "component---src-components-blog-blog-list-js" */),
  "component---src-components-projects-completed-template-js": () => import("./../../../src/components/projects/CompletedTemplate.js" /* webpackChunkName: "component---src-components-projects-completed-template-js" */),
  "component---src-components-projects-ongoing-template-js": () => import("./../../../src/components/projects/OngoingTemplate.js" /* webpackChunkName: "component---src-components-projects-ongoing-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-campaign-index-js": () => import("./../../../src/pages/campaign/index.js" /* webpackChunkName: "component---src-pages-campaign-index-js" */),
  "component---src-pages-campaign-plutus-js": () => import("./../../../src/pages/campaign/plutus.js" /* webpackChunkName: "component---src-pages-campaign-plutus-js" */),
  "component---src-pages-campaign-plutus-thank-you-js": () => import("./../../../src/pages/campaign/plutus-thank-you.js" /* webpackChunkName: "component---src-pages-campaign-plutus-thank-you-js" */),
  "component---src-pages-commercial-completed-projects-js": () => import("./../../../src/pages/commercial-completed-projects.js" /* webpackChunkName: "component---src-pages-commercial-completed-projects-js" */),
  "component---src-pages-commercial-ongoing-projects-js": () => import("./../../../src/pages/commercial-ongoing-projects.js" /* webpackChunkName: "component---src-pages-commercial-ongoing-projects-js" */),
  "component---src-pages-completed-projects-js": () => import("./../../../src/pages/completed-projects.js" /* webpackChunkName: "component---src-pages-completed-projects-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-crown-residence-js": () => import("./../../../src/pages/crown-residence.js" /* webpackChunkName: "component---src-pages-crown-residence-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joint-venture-js": () => import("./../../../src/pages/joint-venture.js" /* webpackChunkName: "component---src-pages-joint-venture-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-ongoing-projects-js": () => import("./../../../src/pages/ongoing-projects.js" /* webpackChunkName: "component---src-pages-ongoing-projects-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-project-status-js": () => import("./../../../src/pages/project-status.js" /* webpackChunkName: "component---src-pages-project-status-js" */),
  "component---src-pages-promenade-js": () => import("./../../../src/pages/promenade.js" /* webpackChunkName: "component---src-pages-promenade-js" */),
  "component---src-pages-royal-damera-gardens-js": () => import("./../../../src/pages/royal-damera-gardens.js" /* webpackChunkName: "component---src-pages-royal-damera-gardens-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-the-peak-js": () => import("./../../../src/pages/the-peak.js" /* webpackChunkName: "component---src-pages-the-peak-js" */),
  "component---src-pages-the-pinnacle-js": () => import("./../../../src/pages/the-pinnacle.js" /* webpackChunkName: "component---src-pages-the-pinnacle-js" */),
  "component---src-pages-walkthrough-crown-index-js": () => import("./../../../src/pages/walkthrough/crown/index.js" /* webpackChunkName: "component---src-pages-walkthrough-crown-index-js" */),
  "component---src-pages-willow-square-js": () => import("./../../../src/pages/willow-square.js" /* webpackChunkName: "component---src-pages-willow-square-js" */)
}

